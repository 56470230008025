import { PinoDayPassProductOptions } from '../../PinoDayPassProductOptions';
import { isGymOpen24Hours } from '../../PinoProducts';
import { PinoTable } from '../../PinoTable';
import { a11yProperties } from '../utils';

import { DayPassesPanelProperties } from './DayPassesPanel.types';

export function DayPassesPanel({
    areAnyMonthly,
    products,
    gymName,
    onSelect,
    productHighlightPills,
    openingHours,
}: DayPassesPanelProperties) {
    const isOpen24Hours = isGymOpen24Hours(openingHours);
    return (
        <>
            <div {...a11yProperties(areAnyMonthly ? 2 : 1)}>
                <PinoTable
                    pinoMonthlyProducts={[products[0]]}
                    gymName={gymName}
                    isOpen24Hours={isOpen24Hours}
                    isDayPassPinoTable
                    showFooter={false}
                    isProductSelectable={false}
                    disabledProduct={undefined}
                />
            </div>

            <PinoDayPassProductOptions
                products={products}
                gymName={gymName}
                onSelect={onSelect}
                productHighlightPills={productHighlightPills}
            />
        </>
    );
}

export default DayPassesPanel;
