import React, { useState } from 'react';

import { encodeAsBase64 } from '@tgg/util';

import {
    StyledTitleContainer,
    StyledTitle,
    StyledIcon,
    StyledIconContainer,
    StyledContent,
} from './Expander.styled';
import { ExpanderProperties } from './Expander.types';

export function Expander({ title, children }: ExpanderProperties) {
    const [isExpanded, setIsExpanded] = useState(false);
    const uniqueId = `expander-${encodeAsBase64(title).slice(0, 10)}`;

    return (
        <>
            <StyledTitleContainer onClick={() => setIsExpanded(!isExpanded)}>
                <StyledIconContainer $expanded={isExpanded}>
                    <StyledIcon name="expand" size={22} />
                </StyledIconContainer>
                <StyledTitle id={uniqueId}>{title}</StyledTitle>
            </StyledTitleContainer>
            <StyledContent
                $expanded={isExpanded}
                aria-expanded={isExpanded}
                aria-labelledby={uniqueId}
            >
                {children}
            </StyledContent>
        </>
    );
}

export default Expander;
