import {
    TggMembershipType,
    PinoProductDetails,
    PinoDetails,
    studentBenefitsHeadings,
    dayPassBenefitsHeadings,
    ProductVariant,
    TggPinoProductTitle,
    benefitsHeadings,
} from '@tgg/common-types';
import { monthlyProductTitleMap } from '@tgg/util';

import { palette } from '../../theme';
import { Icon } from '../Icon';

export const getBenefitsHeadings = () => benefitsHeadings;

const studentDetails: PinoDetails = {
    includedBenefits: {
        'Free classes': {
            display: (
                <div aria-label="student product has 24/7 access to Free classes and Gym Access">
                    <div>24/7</div>
                    <div>access</div>
                </div>
            ),
            rowSpan: 2,
        },
        'Gym Access': {
            display: '',
            rowSpan: 0,
        },
        'Choose 2 gyms': {
            display: (
                <Icon
                    name="tick"
                    color={palette.common.blue}
                    size={18}
                    accessibleTitle="student product has Multi-gym access"
                />
            ),
            rowSpan: 1,
        },
        'Discounts & offers': {
            display: (
                <Icon
                    name="tick"
                    color={palette.common.blue}
                    size={18}
                    accessibleTitle="standard product has Discounts & offers"
                />
            ),
            rowSpan: 1,
        },
        'Fitness & body composition tracking (where available)': {
            display: (
                <Icon
                    name="tick"
                    color={palette.common.blue}
                    size={18}
                    accessibleTitle="student product has Fitness & body composition tracking (where available)"
                />
            ),
            rowSpan: 1,
        },
    },
    title: TggPinoProductTitle.Student,
    position: 0,
};

const dayPassBenefits: PinoDetails = {
    includedBenefits: {
        'Unlimited training': {
            display: (
                <Icon
                    name="tick"
                    color={palette.common.blue}
                    size={18}
                    accessibleTitle="Day pass product has unlimited training access"
                />
            ),
            rowSpan: 1,
        },
        'Free classes': {
            display: (
                <Icon
                    name="tick"
                    color={palette.common.blue}
                    size={18}
                    accessibleTitle="Day pass product has free classes"
                />
            ),
            rowSpan: 1,
        },
        'Single-gym access': {
            display: (
                <Icon
                    name="tick"
                    color={palette.common.blue}
                    size={18}
                    accessibleTitle="student product has single-gym access"
                />
            ),
            rowSpan: 1,
        },
    },
    title: TggPinoProductTitle.DayPass,
    position: 0,
};

export const getPinoProductBenefits = (
    isGuestPassEnabled: boolean,
    isMultipleAddonsEnabled: boolean,
): PinoProductDetails => {
    const getGuestPassBenefit = (membershipType: TggMembershipType) => {
        const isUltimateMembership =
            membershipType === TggMembershipType.UltimateMonthly;
        const isStandartMonthlyMembership =
            membershipType === TggMembershipType.StandardMonthly;
        const membershipLabelMap = {
            [TggMembershipType.UltimateMonthly]: 'ultimate',
            [TggMembershipType.StandardMonthly]: 'standard',
            [TggMembershipType.OffPeakMonthly]: 'off-peak',
        };
        const benefitText = isGuestPassEnabled
            ? '4 Guest Passes per month'
            : 'Bring a friend 4 times per month';

        const benefitContent =
            isStandartMonthlyMembership && isMultipleAddonsEnabled
                ? 'Add to your montly term'
                : '-';

        const ariaLabel = `${
            membershipLabelMap[
                membershipType as keyof typeof membershipLabelMap
            ]
        } product ${
            isUltimateMembership ? 'has' : 'does not have'
        } ${benefitText}`;

        return {
            [benefitText]: {
                display: (
                    <span aria-label={ariaLabel}>
                        {isUltimateMembership ? (
                            <Icon
                                name="tick"
                                color={palette.common.blue}
                                size={18}
                                accessibleTitle={ariaLabel}
                            />
                        ) : (
                            benefitContent
                        )}
                    </span>
                ),
                rowSpan: 1,
            },
        };
    };

    return {
        [TggMembershipType.UltimateMonthly]: {
            includedBenefits: {
                'Free classes': {
                    display: (
                        <div aria-label="ultimate product has 24/7 access to Free classes and Gym Access">
                            <div>24/7</div>
                            <div>access</div>
                        </div>
                    ),
                    rowSpan: 2,
                },
                'Gym Access': {
                    display: '',
                    rowSpan: 0,
                },
                'Access all gyms': {
                    display: (
                        <Icon
                            name="tick"
                            color={palette.common.blue}
                            size={18}
                            accessibleTitle="ultimate product has Multi-gym access"
                        />
                    ),
                    rowSpan: 1,
                },
                'Freeze your monthly membership once for free': {
                    display: (
                        <Icon
                            name="tick"
                            color={palette.common.blue}
                            size={18}
                            accessibleTitle="ultimate product has Freeze your monthly membership once for free"
                        />
                    ),
                    rowSpan: 1,
                },
                ...getGuestPassBenefit(TggMembershipType.UltimateMonthly),
                'Free Fiit workouts & plans worth £20 a month': {
                    display: (
                        <Icon
                            name="tick"
                            color={palette.common.blue}
                            size={18}
                            accessibleTitle="ultimate product has Free Fiit workouts & plans worth £20 a month"
                        />
                    ),
                    rowSpan: 1,
                },
                'Additional benefits': {
                    display: (
                        <Icon
                            name="plus"
                            color={palette.common.blue}
                            size={18}
                            accessibleTitle="additional benefits button expander"
                        />
                    ),
                    rowSpan: 1,
                },
                'Fitness & body composition tracking (where available)': {
                    display: (
                        <Icon
                            name="tick"
                            color={palette.common.blue}
                            size={18}
                            accessibleTitle="ultimate product has Fitness & body composition tracking (where available)"
                        />
                    ),
                    rowSpan: 1,
                },
            },
            title: monthlyProductTitleMap.UltimateMonthly,
            position: 0,
        },
        [TggMembershipType.StandardMonthly]: {
            includedBenefits: {
                'Free classes': {
                    display: (
                        <span aria-label="standard product has 24/7 access to Free classes and Gym Access">
                            <div>24/7</div>
                            <div>access</div>
                        </span>
                    ),
                    rowSpan: 2,
                },
                'Gym Access': {
                    display: '',
                    rowSpan: 0,
                },
                'Access all gyms': {
                    display: (
                        <span aria-label="standard product does not have Multi-gym access">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                'Freeze your monthly membership once for free': {
                    display: (
                        <span aria-label="standard product does not have Freeze your monthly membership once for free">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                ...getGuestPassBenefit(TggMembershipType.StandardMonthly),
                'Free Fiit workouts & plans worth £20 a month': {
                    display: (
                        <span aria-label="standard product does not have Free Fiit workouts & plans worth £20 a month">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                'Additional benefits': {
                    display: (
                        <span aria-label="standard product does not have any additional benefits">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                'Fitness & body composition tracking (where available)': {
                    display: (
                        <span aria-label="standard product does not have Fitness & body composition tracking (where available)">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
            },
            title: monthlyProductTitleMap.StandardMonthly,
            position: 1,
        },
        [TggMembershipType.OffPeakMonthly]: {
            includedBenefits: {
                'Free classes': {
                    display: (
                        <span aria-label="off-peak product has access to Free classes and Gym Access during off-peak hours">
                            <div>during</div>
                            <div>off-peak</div>
                        </span>
                    ),
                    rowSpan: 2,
                    linkHref: '#gym_times_location_section',
                },
                'Gym Access': {
                    display: '',
                    rowSpan: 0,
                },
                'Access all gyms': {
                    display: (
                        <span aria-label="off-peak product does not have Multi-gym access">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                'Freeze your monthly membership once for free': {
                    display: (
                        <span aria-label="off-peak product does not have Freeze your monthly membership once for free">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                ...getGuestPassBenefit(TggMembershipType.OffPeakMonthly),
                'Free Fiit workouts & plans worth £20 a month': {
                    display: (
                        <span aria-label="off-peak product does not have Free Fiit workouts & plans worth £20 a month">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                'Additional benefits': {
                    display: (
                        <span aria-label="off-peak product does not have any additional benefits">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
                'Fitness & body composition tracking (where available)': {
                    display: (
                        <span aria-label="off-peak product does not have Fitness & body composition tracking (where available)">
                            -
                        </span>
                    ),
                    rowSpan: 1,
                },
            },
            title: monthlyProductTitleMap.OffPeakMonthly,
            position: 2,
        },
        [TggMembershipType.StudentFitquestGymAccess]: studentDetails,
        [TggMembershipType.Student3]: studentDetails,
        [TggMembershipType.Student6]: studentDetails,
        [TggMembershipType.Student9]: studentDetails,
        [TggMembershipType.Student12]: studentDetails,
        [TggMembershipType.DayPass]: dayPassBenefits,
        [TggMembershipType.DayPass3]: dayPassBenefits,
        [TggMembershipType.DayPass5]: dayPassBenefits,
    } as unknown as PinoProductDetails;
};

export const membershipBenefitsHeadings = {
    [ProductVariant.Student]: studentBenefitsHeadings,
    [ProductVariant.DayPass]: dayPassBenefitsHeadings,
};
