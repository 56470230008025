import { useMediaQuery } from '@mui/material';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { PinoProduct } from '@tgg/common-types';
import { sendAnalyticsDataEvent, DataEventNames } from '@tgg/services';
import { getTestId, monthlyProductTitleMap } from '@tgg/util';

import { theme } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { PinoProductOption } from '../PinoProductOption';
import { PayPalMessage } from '../PinoProductOptions/PayPalMessage';
import { ProductOptionIcons } from '../PinoProductOptions/ProductOptionIcons';

import {
    generateCTAHref,
    getPinoOrientation,
    getSelectedProduct,
} from './PinoStudentProductOptions.helpers';
import {
    StyledButtonContainer,
    StyledCard,
    StyledFixedProductOptions,
    StyledHeader,
    StyledSectionHeading,
} from './PinoStudentProductOptions.styled';
import { PinoStudentProductOptionsProperties } from './PinoStudentProductOptions.types';

const {
    publicRuntimeConfig: { PAYPAL_CLIENT_ID },
} = getConfig();

export const PinoStudentProductOptions = ({
    gymName,
    ctaHref,
    products,
    onSelect,
    productsAvailableAddons,
    productHighlightPills,
    ctaName = 'Join Now',
    isPaypalIframeVisible,
    isPaypalPayIn3Visible,
    isMBTOptimizationEnabled = false,
}: PinoStudentProductOptionsProperties) => {
    const selectedProduct = getSelectedProduct(products);

    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    const router = useRouter();
    const [optionSelected, setOptionSelected] =
        useState<PinoProduct>(selectedProduct);

    const selectedProductTitle =
        monthlyProductTitleMap[selectedProduct.membershipType];

    const { contentColor } = getPinoColor(selectedProductTitle);

    const handleSelectedProductOption = (product: PinoProduct) => {
        sendAnalyticsDataEvent({
            eventName: DataEventNames.TERM_SELECT,
            eventData: product,
            eventGymName: gymName,
        });
        setOptionSelected(product);
    };

    const handleJoinNow = async () => {
        onSelect(optionSelected);

        sendAnalyticsDataEvent({
            eventName: DataEventNames.JOIN_NOW,
            eventData: optionSelected,
            eventGymName: gymName,
        });

        const generatedCTAHref = generateCTAHref(
            productsAvailableAddons,
            optionSelected,
            ctaHref,
        );

        if (generatedCTAHref) {
            await router.push(generatedCTAHref);
        }
    };

    const orientation = getPinoOrientation(products.length, isDesktop);

    return (
        <>
            <StyledSectionHeading variant="h2" gutterBottom={false}>
                2. Select your student term
            </StyledSectionHeading>
            <StyledCard variant="outlined">
                <StyledHeader>
                    <Heading
                        variant="h3"
                        color={contentColor}
                        gutterBottom={false}
                    >
                        student
                    </Heading>
                    <ProductOptionIcons
                        productTitle={selectedProductTitle}
                        color={contentColor}
                    />
                </StyledHeader>
                {products.length > 0 && (
                    <>
                        <StyledFixedProductOptions>
                            {products
                                .sort(
                                    /* istanbul ignore next */
                                    (a, b) => b.price.value - a.price.value,
                                )
                                .map((product, index) => (
                                    <PinoProductOption
                                        key={product.id}
                                        product={product}
                                        pinoProductSelected={
                                            product.id === optionSelected.id
                                        }
                                        title={selectedProductTitle}
                                        numberOfElements={products.length}
                                        orientation={orientation}
                                        onSelectProductOption={
                                            handleSelectedProductOption
                                        }
                                        pill={
                                            (productHighlightPills &&
                                                productHighlightPills?.find(
                                                    pill =>
                                                        pill.membershipType ===
                                                        product.membershipType,
                                                )) ||
                                            undefined
                                        }
                                        showPaypalPayIn3={isPaypalPayIn3Visible}
                                        isMBTOptimizationEnabled={
                                            isMBTOptimizationEnabled
                                        }
                                    />
                                ))}
                        </StyledFixedProductOptions>

                        {isPaypalIframeVisible && (
                            <PayPalMessage
                                clientId={PAYPAL_CLIENT_ID}
                                amount={optionSelected.price.value}
                            />
                        )}
                    </>
                )}

                <StyledButtonContainer>
                    <ButtonBase
                        type="button"
                        aria-label={`Join Now with ${optionSelected.description} membership`}
                        text={ctaName}
                        onClick={handleJoinNow}
                        data-testid={getTestId('join-now-button')}
                    />
                </StyledButtonContainer>
            </StyledCard>
        </>
    );
};
