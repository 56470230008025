import { useMediaQuery } from '@mui/material';
import getConfig from 'next/config';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { PinoProduct, ProductVariant } from '@tgg/common-types';
import { sendAnalyticsDataEvent, DataEventNames } from '@tgg/services';
import { getTestId, monthlyProductTitleMap } from '@tgg/util';

import { theme } from '../../theme';
import { getPinoColor } from '../../utils/getPinoColors';
import { ButtonBase } from '../Button';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';
import { PinoProductOption } from '../PinoProductOption';

import { PayPalMessage } from './PayPalMessage';
import {
    generateCTAHref,
    getPinoOrientation,
    getProductsArray,
} from './PinoProductOptions.helpers';
import {
    StyledAsteriskText,
    StyledButtonContainer,
    StyledCard,
    StyledDevider,
    StyledFixedProductOptions,
    StyledHeader,
    StyledSectionHeading,
} from './PinoProductOptions.styled';
import { ProductOptionsProperties } from './PinoProductOptions.types';
import { ProductOptionIcons } from './ProductOptionIcons';

const {
    publicRuntimeConfig: { PAYPAL_CLIENT_ID },
} = getConfig();

export const PinoProductOptions = ({
    gymName,
    ctaHref,
    products,
    onSelect,
    productsAvailableAddons,
    productHighlightPills,
    ctaName = 'Join Now',
    isPaypalIframeVisible,
    isPaypalPayIn3Visible,
    isFreeFreezeNoticeVisible,
    isMBTOptimizationEnabled = false,
}: ProductOptionsProperties) => {
    const { Monthly: monthlyProduct, Fixed: fixedProducts } = products;
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const router = useRouter();

    const allProducts = getProductsArray(
        monthlyProduct,
        fixedProducts,
        isMBTOptimizationEnabled,
    );
    const [optionSelected, setOptionSelected] =
        useState<PinoProduct>(monthlyProduct);
    const [isFixedProductSelected, setIsFixedProductSelected] =
        useState<boolean>(false);

    useEffect(() => {
        setOptionSelected(monthlyProduct);
    }, [monthlyProduct]);

    useEffect(() => {
        setIsFixedProductSelected(
            allProducts.includes(optionSelected) &&
                optionSelected.variant !== ProductVariant.Monthly,
        );
    }, [allProducts, optionSelected]);

    const selectedProductTitle =
        monthlyProductTitleMap[monthlyProduct.membershipType];

    const { contentColor } = getPinoColor(selectedProductTitle);

    const handleSelectedProductOption = (product: PinoProduct) => {
        sendAnalyticsDataEvent({
            eventName: DataEventNames.TERM_SELECT,
            eventData: product,
            eventGymName: gymName,
        });
        setOptionSelected(product);
    };

    const handleJoinNow = async () => {
        onSelect(optionSelected);

        sendAnalyticsDataEvent({
            eventName: DataEventNames.JOIN_NOW,
            eventData: optionSelected,
            eventGymName: gymName,
        });

        if (ctaHref) {
            const generatedCTAHref = generateCTAHref(
                productsAvailableAddons,
                optionSelected,
                ctaHref,
            );
            await router.push(generatedCTAHref);
        }
    };

    const orientation = getPinoOrientation(allProducts.length, isDesktop);

    const showFreeFreezeNotice =
        !isFreeFreezeNoticeVisible &&
        optionSelected.membershipType.toLowerCase().includes('ultimate');

    return (
        <>
            {!isMBTOptimizationEnabled && (
                <StyledSectionHeading variant="h2" gutterBottom={false}>
                    2. Select your membership term
                </StyledSectionHeading>
            )}
            <StyledCard
                variant={isMBTOptimizationEnabled ? 'elevation' : 'outlined'}
                data-ab-test={selectedProductTitle}
                $isMBTOptimizationEnabled={isMBTOptimizationEnabled}
            >
                <StyledHeader>
                    <Heading
                        variant="h3"
                        color={contentColor}
                        gutterBottom={false}
                    >
                        {selectedProductTitle}
                        {isMBTOptimizationEnabled && ' membership options'}
                    </Heading>
                    {!isMBTOptimizationEnabled && (
                        <ProductOptionIcons
                            productTitle={selectedProductTitle}
                            color={contentColor}
                        />
                    )}
                </StyledHeader>
                {!isMBTOptimizationEnabled && (
                    <PinoProductOption
                        product={monthlyProduct}
                        pinoProductSelected={
                            monthlyProduct.membershipType ===
                            optionSelected.membershipType
                        }
                        numberOfElements={1}
                        title={selectedProductTitle}
                        onSelectProductOption={handleSelectedProductOption}
                        pill={
                            (productHighlightPills &&
                                productHighlightPills?.find(
                                    pill =>
                                        pill.membershipType ===
                                        monthlyProduct.membershipType,
                                )) ||
                            undefined
                        }
                    />
                )}
                {allProducts.length > 0 && (
                    <>
                        {!isMBTOptimizationEnabled && (
                            <StyledDevider>
                                <div>or</div>
                                <HorizontalRule />
                            </StyledDevider>
                        )}
                        <StyledFixedProductOptions>
                            {allProducts.map((product, index) => (
                                <PinoProductOption
                                    key={product.id}
                                    product={product}
                                    pinoProductSelected={
                                        product.membershipType ===
                                        optionSelected.membershipType
                                    }
                                    title={selectedProductTitle}
                                    numberOfElements={allProducts.length}
                                    orientation={orientation}
                                    onSelectProductOption={
                                        handleSelectedProductOption
                                    }
                                    pill={
                                        (productHighlightPills &&
                                            productHighlightPills?.find(
                                                pill =>
                                                    pill.membershipType ===
                                                    product.membershipType,
                                            )) ||
                                        undefined
                                    }
                                    showPaypalPayIn3={isPaypalPayIn3Visible}
                                    isMBTOptimizationEnabled={
                                        isMBTOptimizationEnabled
                                    }
                                />
                            ))}
                        </StyledFixedProductOptions>
                        {isPaypalIframeVisible && isFixedProductSelected && (
                            <PayPalMessage
                                clientId={PAYPAL_CLIENT_ID}
                                amount={optionSelected.price.value}
                            />
                        )}
                        {showFreeFreezeNotice && (
                            <StyledAsteriskText>
                                * free freeze benefit is not included in saver
                                memberships
                            </StyledAsteriskText>
                        )}
                    </>
                )}

                <StyledButtonContainer>
                    <ButtonBase
                        type="button"
                        aria-label={`Join Now with ${optionSelected.description} membership`}
                        text={ctaName}
                        onClick={handleJoinNow}
                        data-testid={getTestId('join-now-button')}
                    />
                </StyledButtonContainer>
            </StyledCard>
        </>
    );
};
